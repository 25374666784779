import { Align, Justify } from "./types";

export const templates = {
  default: {
    components: [
      {
        style: {
          justify: Justify.center,
          align: Align.center,
          width: 22,
          height: 6,
        },
        template: "Vestaboard Markup Language",
      },
    ],
  },
  plainText: {
    components: [
      {
        template: "Hello World!",
      },
    ],
  },
  centered: {
    components: [
      {
        style: {
          justify: "center",
          align: "center",
        },
        template: "Hello World!",
      },
    ],
  },
  newLine: {
    components: [
      {
        style: {
          justify: "center",
          align: "center",
        },
        template: "Hello\nWorld!",
      },
    ],
  },
  characterCodes: {
    components: [
      {
        style: {
          width: 22,
          height: 6,
        },
        template:
          "{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{63}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{64}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{65}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{66}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{67}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}{68}",
      },
    ],
  },
  characterCodesWithCharacters: {
    components: [
      {
        template:
          "{63} First\n{64} Second\n{65} Third\n{66} Fourth\n{67} Fifth\n{68} Sixth",
      },
    ],
  },
  dynamicProps: {
    props: {
      hours: "07",
      minutes: "35",
    },
    components: [
      {
        style: {
          justify: "center",
          align: "center",
        },
        template: "{{hours}}:{{minutes}}",
      },
    ],
  },
  dynamicPropsWithCharacterCodes: {
    props: {
      hours: "{36}{33}",
      minutes: "{29}{31}",
    },
    components: [
      {
        style: {
          justify: "center",
          align: "center",
        },
        template: "{{hours}}:{{minutes}}",
      },
    ],
  },
  multipleComponents: {
    props: {
      hours: "{36}{33}",
      minutes: "{29}{31}",
      quote: "The world is too much with us",
      author: "William Wordsworth",
    },
    components: [
      {
        style: {
          justify: "center",
          align: "center",
          height: 3,
          width: 22,
        },
        template: "{{hours}}:{{minutes}}",
      },
      {
        style: {
          justify: "center",
          align: "center",
          height: 3,
          width: 22,
        },
        template: "{{quote}} - {{author}}",
      },
    ],
  },
  rawCharactersArray: {
    components: [
      {
        rawCharacters: [
          [
            68, 68, 68, 68, 68, 69, 69, 68, 68, 68, 68, 68, 68, 68, 68, 68, 68,
            68, 68, 68, 68, 68,
          ],
          [
            68, 68, 68, 68, 69, 69, 69, 69, 68, 68, 68, 68, 68, 68, 68, 68, 65,
            65, 65, 65, 68, 68,
          ],
          [
            63, 63, 63, 69, 66, 69, 66, 69, 69, 63, 63, 63, 63, 63, 63, 65, 65,
            65, 65, 65, 65, 63,
          ],
          [
            63, 63, 66, 66, 66, 69, 66, 66, 66, 66, 63, 63, 63, 63, 63, 65, 65,
            65, 65, 65, 65, 63,
          ],
          [
            64, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 64, 64, 64, 64, 64, 65,
            65, 65, 65, 64, 64,
          ],
          [
            66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 64, 64, 64, 64, 64,
            64, 64, 64, 64, 64,
          ],
        ],
      },
    ],
  },

  absolutePositionComponents: {
    props: {
      time: "7:00 PM",
    },
    components: [
      {
        template: "{{time}}",
        style: {
          width: 7,
          height: 1,
          justify: "center",
          absolutePosition: {
            x: 11,
            y: 0,
          },
        },
      },
      {
        template: "Mountain Time",
        style: {
          width: 13,
          height: 1,
          justify: "center",
          absolutePosition: {
            x: 0,
            y: 5,
          },
        },
      },
      {
        rawCharacters: [
          [
            68, 68, 68, 68, 68, 69, 69, 68, 68, 68, 68, 68, 68, 68, 68, 68, 68,
            68, 68, 68, 68, 68,
          ],
          [
            68, 68, 68, 68, 69, 69, 69, 69, 68, 68, 68, 68, 68, 68, 68, 68, 65,
            65, 65, 65, 68, 68,
          ],
          [
            63, 63, 63, 69, 66, 69, 66, 69, 69, 63, 63, 63, 63, 63, 63, 65, 65,
            65, 65, 65, 65, 63,
          ],
          [
            63, 63, 66, 66, 66, 69, 66, 66, 66, 66, 63, 63, 63, 63, 63, 65, 65,
            65, 65, 65, 65, 63,
          ],
          [
            64, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 64, 64, 64, 64, 64, 65,
            65, 65, 65, 64, 64,
          ],
          [
            66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 64, 64, 64, 64, 64,
            64, 64, 64, 64, 64,
          ],
        ],
      },
    ],
  },
  complexMultipleComponents: {
    components: [
      {
        style: {
          justify: "left",
          align: "top",
          height: 1,
          width: 2,
        },
        template: "{67}{63}",
      },
      {
        style: {
          justify: "center",
          align: "top",
          height: 1,
          width: 18,
        },
        template: "Chewbacca",
      },
      {
        style: {
          justify: "left",
          align: "top",
          height: 1,
          width: 2,
        },
        template: "{67}{63}",
      },
      {
        style: {
          justify: "left",
          align: "top",
          height: 1,
          width: 22,
        },
        template: "",
      },
      {
        style: {
          justify: "left",
          align: "top",
          height: 4,
          width: 22,
        },
        template:
          "A legendary Wookiee warrior and Han Solo's longtime co-pilot.",
      },
    ],
  },
  complexLayoutFaqs: {
    components: [
      {
        style: {
          justify: "left",
          align: "top",
          height: 1,
          width: 2,
        },
        template: "{63}{65}",
      },
      {
        style: {
          justify: "center",
          align: "top",
          height: 1,
          width: 18,
        },
        template: "Chewbacca",
      },
      {
        style: {
          justify: "left",
          align: "top",
          height: 1,
          width: 2,
        },
        template: "{63}{65}",
      },
      {
        style: {
          justify: "left",
          align: "top",
          height: 1,
          width: 22,
        },
        template: "",
      },
      {
        style: {
          justify: "right",
          align: "top",
          height: 4,
          width: 9,
        },
        template: "Species: \nHeight: \nSkills: \nWeapon: ",
      },
      {
        style: {
          justify: "left",
          align: "top",
          height: 4,
          width: 13,
        },
        template: "Wookie\n8 ft\nCo-Pilot\nBowcaster",
      },
    ],
  },
};

export const schema = {
  definitions: {},
  type: "object",
  required: ["components"],
  properties: {
    style: {
      type: "object",
      properties: {
        height: {
          type: "number",
        },
        width: {
          type: "number",
        },
      },
    },
    props: {
      type: "object",
    },
    components: {
      type: "array",
      items: {
        type: "object",
        // required: ["template"],
        properties: {
          template: {
            type: "string",
          },
          rawCharacters: {
            type: "array",
            items: {
              type: "array",
              items: {
                type: "number",
              },
            },
          },
          style: {
            type: "object",
            properties: {
              height: {
                type: "number",
              },
              width: {
                type: "number",
              },
              justify: {
                type: "string",
                enum: ["center", "left", "right", "justified"],
              },
              align: {
                type: "string",
                enum: ["center", "top", "bottom", "justified"],
              },
              absolutePosition: {
                type: "object",
                properties: {
                  x: {
                    type: "number",
                  },
                  y: {
                    type: "number",
                  },
                }
              }
            },
          },
        },
      },
    },
  },
};

import { Box, Typography, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  BoardPreview,
  Body,
  Select,
  Spacer,
  Title,
} from "@vestaboard/installables";
import { useEffect, useState } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/monokai";
import Ajv from "ajv";
import { useFormat } from "./useFormat";
import { IVBML } from "./types";
import { schema } from "./schema";
import validate from "./validate";
import { templates } from "./templates";

const ajv = new Ajv({ allErrors: true, verbose: true });

const useStyles = makeStyles({
  container: {
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width: 1100px)": {
      flexDirection: "column-reverse",
    },
  },
  editor: {
    width: "48%",
    "@media(max-width: 1100px)": {
      width: "100%",
    },
  },
  preview: {
    width: "48%",
    marginTop: 16,
    "@media(max-width: 1100px)": {
      width: "100%",
      marginTop: 0,
      marginBottom: 24,
    },
  },
  editorBox: {
    marginTop: 50,
    "& > *": {
      height: 635,
    },
  },
  header: {
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
  },
  characters: {
    marginTop: 32,
    background: "#111",
    borderRadius: 12,
    padding: 24,
  },
  line: {},
  mono: {
    fontFamily: "Roboto Mono, monospace",
  },
});

export const App = () => {
  const classes = useStyles();
  const { format, message } = useFormat();
  const [error, setError] = useState<string | null>(null);
  const [template, setTemplate] = useState("default");
  const [json, setJson] = useState<IVBML>(templates.default);

  const templateNames = Object.keys(templates);

  useEffect(() => {
    const error = validate(schema, json);

    if (!error) {
      setError(null);
      format(json);
    } else {
      setError(error);
    }
  }, [json, format]);

  return (
    <>
      <Box className={classes.header}>
        <Box>
          <Title>VBML</Title>
          <Body>The Vestaboard Markup Language</Body>
        </Box>
        <Box className={classes.headerRight}>
          <Body>Template:</Body>
          <div style={{ width: 14 }} />
          <Select
            options={templateNames.map((name) => ({
              name,
              id: name,
            }))}
            value={template}
            onValueChange={(value) => {
              setTemplate(value);
              setJson(templates[value]);
            }}
          ></Select>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.editor}>
          <Box className={classes.editorBox}>
            <Editor
              key={template}
              value={json}
              onChange={setJson}
              mode="code"
              ace={ace}
              theme="ace/theme/monokai"
              schema={schema}
              ajv={ajv}
            />
          </Box>
        </Box>
        <Box className={classes.preview}>
          <BoardPreview characters={message} />
          {error ? (
            <>
              <Spacer size="large" />
              <Alert severity="warning">{error}</Alert>
            </>
          ) : null}
          <Box className={classes.characters}>
            <Typography className={classes.mono}>{"["}</Typography>
            <>
              {message.map((row, index) => {
                return (
                  <Box key={index} className={classes.line}>
                    <Typography className={classes.mono}>
                      &nbsp;&nbsp;
                      {JSON.stringify(row)}
                      {index === message.length - 1 ? null : ","}
                    </Typography>
                  </Box>
                );
              })}
            </>
            <Typography className={classes.mono}>{"]"}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

import { useCallback, useState } from "react";
import { IVBML } from "./types";
import { IBoard, createBoard } from "@vestaboard/installables";

const ENDPOINT = `${
  process.env.REACT_APP_API_URL || "https://vbml.vestaboard.com"
}/compose`;

export const useFormat = () => {
  const [message, setMessage] = useState(createBoard());

  const format = useCallback(async (vbml: IVBML) => {
    const result = await fetch(ENDPOINT, {
      method: "POST",
      body: JSON.stringify(vbml),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    if (Array.isArray(result)) {
      setMessage(result as IBoard);
    }
  }, []);

  return {
    format,
    message,
  };
};
